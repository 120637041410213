<template>
  <div>
    <Claims :selectedPageTemplate="selectedPageTemplate"/>
  </div>
</template>

<script>
  export default {
    components: {
      Claims: () => import('@/components/Design/View/ViewTheme'),
    },
    data: () => ({
      selectedPageTemplate: 'Claims',
    }),
    created () {
    }
  }
</script>
